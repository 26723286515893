import { useEffect } from "react"

import { logout, getCurrentUser } from "../components/authentication"
import { navigate } from "gatsby"

const Logout = () => {
  const { impersonated } = getCurrentUser() || {}
  useEffect(() => {
    logout()
    impersonated ? navigate("/dashboard") : navigate("/")
  }, [impersonated])
  return null
}

export default Logout
